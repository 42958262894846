export const enum MENU_PATHS {
  login = '/login',
  cart = '/cart',
  payment = '/payment',
  category = '/category/:categoryUniqueName',
  promos = '/promos',
  promoItems = '/promo/:uuid/items',
  rewardItems = '/reward/:uuid/items',
  loyalty_info = '/loyalty-info',
  notifications = '/notifications',
  profile = '/profile',
  reorder = '/order-details/:orderId',
  order_setup = '/order-setup',
  edit_profile = '/edit-profile',
  last_orders = '/latest-orders',
  item_modal = '/item/:itemUniqueName',
  edit_item_modal = '/item/:itemUniqueName/:editable',
  item_group = '/item-group/:itemUniqueName',
  buy_gift_card = '/buy-gift-card',
  edit_gift_card = '/gift-cards/:giftCardId/edit',
  user_gift_cards = '/my-gift-cards',
  add_gift_card = '/add-gift-card',
  checkout = '/checkout',
  order_complete = '/order-complete/:orderId',
  add_combo = '/combo/:comboUniqueName',
  edit_combo = '/combo/:comboUniqueName/edit',
}
